import Table from './components/windtable';
import logo from './logo.svg';
import './App.css';
import axios from "axios";
import React, { useState, useEffect } from "react";

function App() {
  const [data, setData] = useState({});
  
  const fetchData = async () => {
    const response = await fetch('https://static.avalanche.report/weather_stations/stations.geojson');
    const jsonData = await response.json();
    console.log(jsonData)
    setData(jsonData)
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Table data={data}/>
  );
}

export default App;
